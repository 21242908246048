@mixin navbar-colors($color, $link-color) {
  color: $link-color;
  background-color: $color !important;
  @include shadow-navbar-color($color);

  .dropdown-item:hover,
  .dropdown-item:focus {
    @include shadow-small-color($color);
    background-color: $color;
    color: $link-color;
  }

  .navbar-toggler {
    .navbar-toggler-icon {
      background-color: $link-color;
    }
  }
}
