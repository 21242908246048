@media all and (max-width: 991px) {
  [class*="navbar-expand-"] > .container,
  [class*="navbar-expand-"] > .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar .navbar-collapse .navbar-nav > li.button-container {
    padding: 15px;
  }

  .carousel .card .card-body {
    max-width: 340px;
    margin: 0 auto;
    min-height: 400px;
  }

  .navbar-collapse {
    position: fixed;
    display: block;
    top: 0px;
    height: 100vh;
    width: 230px;
    right: 0;
    margin-right: 0 !important;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0;
    padding-left: 0;

    max-height: none !important;

    @include transform-translate-x(230px);
    @include transitions(0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    &::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: $white-color;
      display: block;
      content: "";
      z-index: 1;
    }

    .dropdown-toggle:after {
      position: absolute;
      right: 16px;
      margin-top: 8px;
    }

    .navbar-nav {
      position: relative;
      z-index: 3;

      .nav-item {
        .nav-link {
          color: $black-color;
          margin: 5px 15px;
        }

        &.button-container .nav-link {
          margin: 15px;
        }

        &:after {
          width: calc(100% - 30px);
          content: "";
          display: block;
          height: 1px;
          margin-left: 15px;
          // background-color: #e5e5e5;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

    .nav-open & {
      @include transform-translate-x(0px);
    }
  }

  .nav-open {
    .navbar-translate {
      @include transform-translate-x(-230px);
    }
  }

  .navbar {
    .navbar-translate {
      width: 100%;
      position: relative;
      display: flex;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
      -ms-flex-align: center;
      align-items: center;
      @include transitions-property(transform, 0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
    }

    .dropdown-menu {
      .dropdown-item {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      padding-bottom: 15px;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      transform: none !important;
      width: auto;
      margin-bottom: 15px;
      padding-top: 0;
      height: 300px;
      animation: none;
      opacity: 1;
      overflow-y: scroll;
    }
  }

  .navbar.navbar-transparent {
    .navbar-toggler {
      .navbar-toggler-icon {
        background-color: $white-color;
      }
    }
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 230px;
    content: "";
    z-index: 1029;
    overflow-x: hidden;
  }

  // for demo
  #navbar .navbar-collapse,
  #navigation .navbar-collapse {
    display: none !important;
  }

}

@media all and (min-width: 991px) {
  .navbar .navbar-nav {
    align-items: center;

    .button-container {
      margin-left: 0.1875px;
    }
  }
}

@media screen and (max-width: 991px) {
  .presentation-page {
    .section-components {
      .components-macbook {
        max-width: 850px !important;
        max-height: 480px !important;
        margin-top: 12vh;
        left: -12px;
      }

      .coloured-card-img,
      .table-img {
        display: none;
      }

      .social-img {
        left: 47%;
        top: 37%;
      }

      .pin-btn-img {
        top: 54%;
      }

      .share-btn-img {
        top: 12%;
      }

      .coloured-card-btn-img {
        top: -2%;
        left: 65%;
      }
    }

    .section-content {
      .area-img {
        max-width: 130px;
        max-height: 170px;
      }

      .info-img {
        max-width: 170px;
        max-height: 120px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .presentation-page {
    .section-components {
      .components-macbook {
        max-width: 350px !important;
        max-height: 250px !important;
        margin-top: 12vh;
        left: -12px;
      }

      .coloured-card-img,
      .table-img {
        display: none;
      }

      .social-img {
        left: -7%;
        top: 37%;
      }

      .pin-btn-img {
        top: 54%;
      }

      .share-btn-img {
        top: 7%;
      }

      .coloured-card-btn-img {
        top: -2%;
      }
    }
  }

  .presentation-page,
  .index-page,
  .section-page {
    #cd-vertical-nav {
      display: none;
    }
  }

  .index-page {
    .cd-section {
      .tim-typo .tim-note {
        width: 60px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .pro-badge {
    top: 90px !important;
    right: 30px !important;
  }
  .cd-vertical-nav {
    display: none !important;
  }
}

/*          Changes for small display      */

@media (max-width: 991px) {

  .form-group {
    textarea {
      padding-top: 15px;
    }
  }

  .nav-open .menu-on-left .main-panel {
    position: initial;
  }

  html,
  body {
    overflow-x: hidden;
  }

  .menu-on-left {
    .nav-open & {
      .main-panel,
      .wrapper-full-page,
      .navbar-fixed > div {
        @include transform-translate-x(260px);
      }
    }

    .sidebar,
    .off-canvas-sidebar {
      left: 0;
      right: auto;
      @include transform-translate-x(-260px);
    }

    .close-layer {
      left: auto;
      right: 0;
    }
  }

  .timeline {
    &:before {
      left: 5%;
    }

    > li > .timeline-badge {
      left: 5%;
    }

    > li > .timeline-panel {
      float: right;
      width: 86%;

      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }

  .nav-mobile-menu {
    .dropdown .dropdown-menu {
      display: none;
      position: static !important;
      background-color: transparent;
      width: auto;
      float: none;
      box-shadow: none;

      &.showing {
        animation: initial;
        animation-duration: 0s;
      }

      &.hiding {
        transform: none;
        opacity: 1;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    li.active > a {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }


  .navbar-minimize {
    display: none;
  }

  .card .form-horizontal {
    .label-on-left,
    .label-on-right {
      padding-left: 15px;
      padding-top: 8px;
    }

    .form-group {
      margin-top: 0px;
    }

    .checkbox-radios {
      padding-bottom: 15px;

      .checkbox:first-child,
      .radio:first-child {
        margin-top: 0;
      }
    }

    .checkbox-inline {
      margin-top: 0;
    }
  }
  .sidebar {
    display: none;
    box-shadow: none;

    .sidebar-wrapper {
      padding-bottom: 60px;
    }

    .nav-mobile-menu {
      margin-top: 0;

      .notification {
        float: left;
        line-height: 30px;
        margin-right: 8px;
      }

      .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

  .main-panel {
    width: 100%;
  }
  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45);
  }
  body {
    position: relative;
  }

  .nav-open {
    .main-panel,
    .wrapper-full-page,
    .navbar .container .navbar-toggler,
    .navbar .container .navbar-wrapper,
    .navbar .container {
      left: 0;
      @include transform-translate-x(-260px);
    }

    .sidebar {
      @include shadow-big();
    }

    .off-canvas-sidebar .navbar-collapse,
    .sidebar {
      @include transform-translate-x(0);
    }
  }

  .wrapper-full-page,
  .navbar .container .navbar-toggler,
  .navbar .container .navbar-wrapper,
  .navbar .container {
    @include transform-translate-x(0px);
    @include transitions(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    left: 0;
  }

  .off-canvas-sidebar .navbar .container {
    transform: none;
  }


  .main-panel,
  .navbar-collapse {
    @include transitions(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .navbar .navbar-collapse.collapse,
  .navbar .navbar-collapse.collapse.in,
  .navbar .navbar-collapse.collapsing {
    display: none !important;
  }

  .off-canvas-sidebar .navbar .navbar-collapse.collapse,
  .off-canvas-sidebar .navbar .navbar-collapse.collapse.in,
  .off-canvas-sidebar .navbar .navbar-collapse.collapsing {
    display: block !important;
  }

  .navbar-nav > li {
    float: none;
    position: relative;
    display: block;
  }

  .off-canvas-sidebar nav .navbar-collapse {
    margin: 0;

    > ul {
      margin-top: 19px;
    }
  }

  .sidebar,
  .off-canvas-sidebar nav .navbar-collapse {
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    width: 260px;
    right: 0;
    left: auto;
    z-index: 1032;
    visibility: visible;
    background-color: #9A9A9A;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0px;
    padding-left: 0;

    @include transform-translate-x(260px);
    @include transitions(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    > ul {
      position: relative;
      z-index: 4;
      width: 100%;
    }

    &::before {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #282828;
      display: block;
      content: "";
      z-index: 1;
    }

    .logo {
      position: relative;
      z-index: 4;
    }

    .navbar-form {
      margin: 10px 0px;
      float: none !important;
      padding-top: 1px;
      padding-bottom: 1px;
      position: relative;
    }

    .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
    }
  }

  .form-group {

    &.form-search {
      .form-control {
        font-size: 1.7em;
        height: 37px;
        width: 78%;
      }
    }
  }

  .navbar-form {
    .btn {
      position: absolute;
      top: -5px;
      right: -50px;
    }
  }

  .close-layer {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: auto;
    background: rgba(0, 0, 0, 0.35);
    content: "";
    z-index: 9999;
    overflow-x: hidden;

    @include transitions($slow-transition-time, $transition-ease-in);

    &.visible {
      opacity: 1;
    }
  }

  .navbar-toggler .icon-bar {
    display: block;
    position: relative;
    background: #555 !important;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
  }

  .navbar-header .navbar-toggler {
    padding: 15px;
    margin-top: 4px;
    width: 40px;
    height: 40px;
  }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  .navbar-toggler {
    .icon-bar:nth-child(2) {
      top: 0px;
      @include bar-animation($topbar-back);
    }

    .icon-bar:nth-child(3) {
      opacity: 1;
    }

    .icon-bar:nth-child(4) {
      bottom: 0px;
      @include bar-animation($bottombar-back);
    }

    &.toggled {
      .icon-bar:nth-child(2) {
        top: 6px;
        @include bar-animation($topbar-x);
      }

      .icon-bar:nth-child(3) {
        opacity: 0;
      }

      .icon-bar:nth-child(4) {
        bottom: 6px;
        @include bar-animation($bottombar-x);
      }
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dropdown-menu .divider {
    background-color: rgba(229, 229, 229, 0.15);
  }

  .navbar-nav {
    margin: 1px 0;

    .open .dropdown-menu > li {
      & > a {
        padding: 15px 15px 5px 50px;
      }

      &:first-child > a {
        padding: 5px 15px 5px 50px;
      }

      &:last-child > a {
        padding: 15px 15px 25px 50px;
      }
    }
  }

  [class*="navbar-"] .navbar-nav {
    & > li > a,
    > li > a:hover,
    > li > a:focus,
    .active > a,
    .active > a:hover,
    .active > a:focus,
    .open .dropdown-menu > li > a,
    .open .dropdown-menu > li > a:hover,
    .open .dropdown-menu > li > a:focus,
    .navbar-nav .open .dropdown-menu > li > a:active {
      color: white;
    }

    & > li > a,
    > li > a:hover,
    > li > a:focus,
    .open .dropdown-menu > li > a,
    .open .dropdown-menu > li > a:hover,
    .open .dropdown-menu > li > a:focus {
      opacity: .7;
      background: transparent;
    }

    &.navbar-nav .open .dropdown-menu > li > a:active {
      opacity: 1;
    }

    & .dropdown > a {
      &:hover .caret {
        border-bottom-color: #777;
        border-top-color: #777;
      }

      &:active .caret {
        border-bottom-color: white;
        border-top-color: white;
      }
    }

  }

  .dropdown-menu {
    display: none;
  }
  .navbar-fixed-top {
    -webkit-backface-visibility: hidden;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }

  .social-line .btn {
    margin: $margin-bottom;
  }
  .subscribe-line .form-control {
    margin: $margin-bottom;
  }
  .social-line.pull-right {
    float: none;
  }
  .footer:not(.footer-big) nav > ul li {
    float: none;
  }
  .social-area.pull-right {
    float: none !important;
  }
  .form-control + .form-control-feedback {
    margin-top: -8px;
  }
  .navbar-toggle:hover, .navbar-toggle:focus {
    background-color: transparent !important;
  }

  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px;
  }
  .media-post .media-body {
    width: 100%;
  }

  .navbar-collapse.collapse {
    height: 100% !important;
  }
  .navbar-collapse.collapse.in {
    display: block;
  }
  .navbar-header .collapse, .navbar-toggle {
    display: block !important;
  }
  .navbar-header {
    float: none;
  }
  .navbar-collapse {
    .nav p {
      font-size: $font-size-base;
      margin: 0;
    }
  }
}


@media (min-width: 992px) {
  .main-panel {
    .navbar .navbar-collapse {
      .navbar-nav .nav-item .nav-link p {
        display: none;
      }
    }
  }

  .nav-mobile-menu,
  .sidebar .navbar-form {
    display: none !important;
  }
}
